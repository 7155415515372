import {Alert, Button, Grid, Paper, Snackbar, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import MarengoCopyright from "../components/MarengoCopyright";
import PetInfoFromResearch from "../components/PetInfoFromResearch";
import marengoVetygo from '../images/marengoVetygo.png';

export interface IHomePageProps {
}

const HomePage: React.FunctionComponent<IHomePageProps> = () => {
    const {t} = useTranslation();
    let [petIdentification, setPetIdentification] = useState<string>('');
    let [searchText, setSearchText] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [searchParams] = useSearchParams();


    const goToMicrochipNumber = () => {
        setPetIdentification(searchText);
    }

    return (
        <div>
            <Paper style={{
                backgroundColor: "#404EED",
                color: "#FFFFFF",
                padding: "1rem",
            }}>
                <Grid item xs={12} md={3} lg={3}>
                    <Typography>
                        {t("quebec_company")}
                    </Typography>
                </Grid>
            </Paper>
            <Paper style={{
                backgroundColor: "#FFFFFF",
                padding: "1rem",
            }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid container direction="column" alignItems={"start"}>
                            <Grid item>
                                <Button
                                    style={{
                                        borderRadius: 10,
                                        color: "#5B5B5B",
                                        textAlign: "left", textTransform: "none",
                                    }}
                                    onClick={() => window.location.href = "https://vetygo.ca"}>
                                    Retour vers le site www.vetygo.ca
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    style={{
                                        borderRadius: 10,
                                        color: "#5B5B5B",
                                        textAlign: "left",
                                        textTransform: "none",
                                    }}
                                    onClick={() => window.location.href = "https://marengoapp.ca/"}>
                                    Retour vers le site www.marengoapp.ca
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div>
                            <img src={marengoVetygo} width={'300px'} alt="Logo"/>
                        </div>
                    </Grid>
                </Grid>
            </Paper>

            <Paper style={{
                backgroundColor: "#CCE8FE",
                borderBottomRightRadius: 25,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 25,
                color: "#FFFFFF",
                padding: "1rem",
            }}>

                <br/>
                <br/>
                <Typography variant='h3' fontWeight={"bold"} color={"#5B5B5B"}>{t("have_found_a_pet")}</Typography>
                <br/>
                <br/>
                <div>
                    <Typography variant='h5' color={"#5B5B5B"}>{t("enter_pet_id_code")}</Typography>
                    <br/>
                    <br/>
                    <Grid container alignContent={"center"} alignItems={"center"} style={{display: "block"}}>
                        <TextField
                            id="tagcode"
                            onChange={(event) => setSearchText(event.target.value)}
                            placeholder="xxxxxxxxxxxxxxx"
                            style={{
                                backgroundColor: "#FFFFFF",
                                borderRadius: 10,
                                borderColor: "#404EED",
                                borderWidth: "10px",
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={goToMicrochipNumber}
                            style={{
                                marginLeft: "1rem",
                                backgroundColor: "#FFFFFF",
                                color: "#404EED",
                                padding: "1rem",
                                borderRadius: 10,
                                border: "2px solid #404EED",
                            }}>
                            <Typography color={"#404EED"}>{t("search")}</Typography>
                        </Button>
                        <br/>
                        <br/>
                        <Typography color={"#5B5B5B"}>{t("iso_number")}</Typography>
                        <Typography color={"#5B5B5B"}>{t("tag_number")}</Typography>
                        <Typography color={"#5B5B5B"}>{t("rage_number")}</Typography>
                        <Typography color={"#5B5B5B"}>{t("citY_license")}</Typography>
                    </Grid>
                </div>
                <br/>
                <br/>
            </Paper>
            {petIdentification && (
                <PetInfoFromResearch petIdentification={petIdentification}/>
            )}
            <br/>
            <br/>
            <br/>
            <br/>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setOpenSnackbar(false)}
                    sx={{fontSize: '1.2rem'}}
                    severity="error" // Change the severity as needed (success, error, warning, info)
                >
                    {t("microchipRegexAlert")}
                </Alert>
            </Snackbar>
            <MarengoCopyright/>
        </div>
    );
};

export default HomePage;